/* Base styles for larger screens */
body {
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  color: #333;
}

.container {
  background-color: #fff;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  width: 450px;
  max-width: 90%;
  text-align: center;
}

h2 {
  margin-bottom: 20px;
  color: #444;
  font-size: 24px;
}

.input-group {
  margin-bottom: 20px;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 8px;
  color: #666;
  font-weight: bold;
  font-size: 14px;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 14px;
  color: #555;
  transition: all 0.3s ease;
}

input[type="text"]:focus,
textarea:focus {
  border-color: #6e8efb;
  box-shadow: 0 0 8px rgba(110, 142, 251, 0.2);
}

textarea {
  height: 80px;
  resize: vertical;
}

.rating-group {
  margin-bottom: 20px;
  text-align: left;
}

.rating {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  gap: 10px;
}

.rating span {
  font-size: 30px;
  cursor: pointer;
  color: #ccc;
  transition: color 0.3s ease;
}

.rating span:hover,
.rating span.active {
  color: #ffd700;
}

.submit-btn {
  width: 100%;
  padding: 15px;
  background-color: #6e8efb;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #a777e3;
}

/* Responsive styles */
@media (max-width: 768px) {
  .container {
    width: 100%;
    padding: 15px;
  }

  h2 {
    font-size: 20px;
  }

  input[type="text"],
  textarea {
    font-size: 16px;
  }

  .rating span {
    font-size: 25px;
  }

  .submit-btn {
    font-size: 14px;
    padding: 12px;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 10px;
  }

  h2 {
    font-size: 18px;
  }

  input[type="text"],
  textarea {
    font-size: 14px;
    padding: 10px;
  }

  .rating span {
    font-size: 20px;
  }

  .submit-btn {
    font-size: 12px;
    padding: 10px;
  }
}
